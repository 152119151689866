import React from 'react'
import { Box, Flex } from 'reflexbox'
import * as styles from './Tickets.module.scss'
import { Container, Row, Column } from 'src/components/UI/Grid/Grid'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import SbEditable from 'storyblok-react'
import { getLinkAttributes } from 'src/helpers/getLinkAttributes'
import classnames from 'classnames'
import Components from 'src/storyblok/Components'
const Tickets = ({ blok }: any) => {
  const { title, description, tickets, anchor_id, theme } = blok
  return (
    <SbEditable content={blok}>
      <Box id={anchor_id} className="anchor"></Box>
      <Container
        id={anchor_id}
        className={classnames(
          styles.wrapper,
          theme && theme === 'sand' ? styles.sand : styles.purple,
          theme && theme === 'sand' ? 'sand-section' : 'purple-section'
        )}
        paddingY={[16, null, null, 38]}
      >
        {(title || description) && (
          <Box marginBottom={[10, null, null, 14]}>
            {title && (
              <Row justifyContent="center">
                <Column width={[1, null, null, 8 / 12]}>
                  <Box as="h2" className={styles.title}>
                    {title}
                  </Box>
                </Column>
              </Row>
            )}
            {description && (
              <Row justifyContent="center">
                <Column width={[1, null, null, 6 / 12]}>
                  <Box
                    as="p"
                    className={styles.description}
                    marginTop={[5, null, null, 10]}
                  >
                    {description}
                  </Box>
                </Column>
              </Row>
            )}
          </Box>
        )}

        <Flex flexDirection="column" width={1} alignItems="center">
          <Box
            maxWidth={['100%', null, null, 'var(--container-max-width)']}
            width={1}
          >
            <Row flexDirection="row" justifyContent="center">
              {tickets &&
                tickets.length > 0 &&
                tickets.map((ticket: any, index: number) => {
                  const {
                    _uid,
                    title,
                    subtitle,
                    link,
                    bullet_list,
                    sold_out,
                  } = ticket
                  const { url = '' } = getLinkAttributes({ url: link })

                  return (
                    <Column
                      key={`${_uid}`}
                      width={[1, null, null, 4 / 12]}
                      marginBottom={[
                        bullet_list && bullet_list?.length > 0 ? 7 : 5,
                        null,
                        null,
                        0,
                      ]}
                    >
                      <Flex justifyContent="center">
                        <Box width={[4 / 5, null, null, 1]}>
                          <LinkComponent
                            to={url}
                            className={classnames(
                              styles.ticketLink,
                              sold_out ? styles.soldOut : ''
                            )}
                          >
                            <Box className={styles.ticket}>
                              <Flex
                                className={styles.ticketContent}
                                alignContent="center"
                                justifyContent="center"
                                flexDirection="column"
                                textAlign="center"
                              >
                                {title && (
                                  <Box as="span" className={styles.ticketTitle}>
                                    {title}
                                  </Box>
                                )}
                                {subtitle && (
                                  <Flex
                                    as="span"
                                    justifyContent="center"
                                    className={styles.ticketSubtitle}
                                  >
                                    <Box as="span">{subtitle}</Box>
                                    {sold_out && (
                                      <Box
                                        as="span"
                                        className={styles.ticketInfo}
                                      >
                                        {' - SOLD OUT'}
                                      </Box>
                                    )}
                                  </Flex>
                                )}
                              </Flex>
                            </Box>
                          </LinkComponent>
                          {bullet_list && bullet_list?.length > 0 && (
                            <Box
                              as="ul"
                              className={styles.bulletList}
                              marginTop={[4, null, null, 6]}
                            >
                              {bullet_list.map((bullet: any) => (
                                <Box
                                  as="li"
                                  key={bullet?._uid}
                                  marginBottom={[2]}
                                  className={styles.bullet}
                                >
                                  {bullet?.title && <Box>{bullet.title}</Box>}
                                </Box>
                              ))}
                            </Box>
                          )}
                        </Box>
                      </Flex>
                    </Column>
                  )
                })}
            </Row>
          </Box>
        </Flex>
      </Container>
    </SbEditable>
  )
}

export default Tickets
